import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "./more-info.css";

const MoreInfoLate = () => {
  return (
    <>
      <Header />
      <div className="moreInfoLateCont">
        <h1>Coming Soon....</h1>
      </div>
      <Footer />
    </>
  );
};

export default MoreInfoLate;
